import { render, staticRenderFns } from "./TopProducts.vue?vue&type=template&id=635d63dc"
import script from "./TopProducts.vue?vue&type=script&lang=js"
export * from "./TopProducts.vue?vue&type=script&lang=js"
import style0 from "./TopProducts.vue?vue&type=style&index=0&id=635d63dc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarketProductsMPProductItem: require('/usr/src/app/components/Market/Products/MPProductItem.vue').default,UIMainPageProductsSkeleton: require('/usr/src/app/components/UI/MainPageProductsSkeleton.vue').default,UIKeenSlider: require('/usr/src/app/components/UI/KeenSlider.vue').default})
